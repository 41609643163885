.landing-page {
    display: flex;
    flex-direction: column;
    background: #03adf4;
    height: 100vh;
    margin: 0;
    width: 100%;
    color: white;
}

.landing-content {
    background: white;
    border-radius: 0.25rem;
    text-align: center;
    padding: 1rem;
}
